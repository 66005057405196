import React from "react"
import { darkGreen, grey30, primaryBlue } from "./palette"
import styled from "styled-components"
import Padding from "./Padding"
import { Breakpoint } from "./breakpoints"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledSection = styled.section`
  background: ${grey30};
  position: relative;
  padding-bottom: 3rem;
  color: white;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`

const Partner = styled.p`
  color: ${primaryBlue};
`

const Description = styled.p`
  color: ${primaryBlue};
  font-family: Raleway;
  font-weight: bold;
  font-size: 2rem;
  font-style: italic;
  letter-spacing: 0.1rem;
  text-align: center;
  @media only screen and (max-width: ${Breakpoint.small}) {
    font-size: 1.5rem;
  }
`

const MiniTitle = styled.p`
  margin-bottom: 1rem;
  color: ${darkGreen};
  font-size: 1rem;
  font-family: Open Sans;
  font-weight: normal;
`

enum ImageType {
  ai = "ai",
}

const Image = ({ image }: { image: ImageType }) => {
  const data = useStaticQuery(graphql`
    query {
      ai: file(relativePath: { eq: "ai_illustration.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img
      style={{
        position: "absolute",
        width: "100%",
        objectFit: "cover",
        zIndex: 0,
      }}
      fluid={data[image].childImageSharp.fluid}
    />
  )
}

const ContentWrap = styled.div`
  z-index: 3;
  position: relative;
`

const BackgroundFader = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${grey30};
  opacity: 0.6;
`

const VisionSection = () => {
  return (
    <StyledSection>
      <Image image={ImageType.ai} />
      <BackgroundFader />
      <ContentWrap>
        <Padding>
          <Column>
            <MiniTitle></MiniTitle>
            <Row>
              <Description>
                If you can hear the sound of a gunshot Triangula will detect and
                position it. Triangula uses advanced artificial intelligence
                (AI) to position gunshot, identify weapon and find gunshot direction
              </Description>
            </Row>
          </Column>
        </Padding>
      </ContentWrap>
    </StyledSection>
  )
}

export default VisionSection
