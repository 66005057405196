import React from "react"
import Padding from "./Padding"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import { primaryBlue } from "./palette"
import { Breakpoint } from "./breakpoints"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

enum ImageType {
  ffi = "ffi",
  forskningsraadet = "forskningsraadet",
}

const Image = ({
  image,
}: {
  image: ImageType
  top?: string
  right?: string
  left?: string
}) => {
  const data = useStaticQuery(graphql`
    query {
      ffi: file(relativePath: { eq: "ffi.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      forskningsraadet: file(relativePath: { eq: "forskningsraadet.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      style={{
        width: "100%",
        maxWidth: "14rem",
      }}
      fluid={data[image].childImageSharp.fluid}
    />
  )
}

const StyledSection = styled.section`
  background: white;
  position: relative;
  padding-bottom: 5rem;
  color: ${primaryBlue};
  overflow: hidden;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Partner = styled.div`
  margin-top: 2rem;
  @media only screen and (min-width: ${Breakpoint.medium}) {
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: 20rem;
  }
`

const ImgWrapper = styled.div`
  height: 70px;
`

const FFI = () => {
  return (
    <Partner>
      <a href="https://www.ffi.no/en">
        <ImgWrapper>
          <Image image={ImageType.ffi} />
        </ImgWrapper>
      </a>
      <Paragraph>
        Norwegian Defence Research Establishment (FFI) is the prime institution
        responsible for defence related research and development in Norway.
      </Paragraph>
      <Paragraph>
        Triangula is developed with support from FFI and Norwegian Armed Forces
        through governmental grants and assistance during development and
        testing.
      </Paragraph>
    </Partner>
  )
}

const FRMargin = styled.div`
  margin-top: 0.7rem;
  margin-bottom: -0.7rem;
`

const Paragraph = styled.p`

`

const Forskningsraadet = () => {
  return (
    <Partner>
      <FRMargin>
        <a href="https://www.forskningsradet.no/en/">
          <ImgWrapper>
            <Image image={ImageType.forskningsraadet} />
          </ImgWrapper>
        </a>
      </FRMargin>
      <Paragraph>
        The Research Council of Norway promotes competitiveness and growth in
        Norwegian trade and industry by providing financial support for research
        and innovation projects.
      </Paragraph>
      <Paragraph>
        Triangula is supported by the Research Council of Norway through their
        SkatteFUNN program.
      </Paragraph>
    </Partner>
  )
}

const SupportedBySection = () => {
  return (
    <StyledSection id={"supported-by"}>
      <Padding>
        <Column>
          <SectionTitle title={"Supported by"} />
          <Row>
            <FFI />
            <Forskningsraadet />
          </Row>
        </Column>
      </Padding>
    </StyledSection>
  )
}

export default SupportedBySection
