import React from "react"
import SEO from "../components/seo"
import HeadlineSection from "../components/HeadlineSection"
import SolutionSection from "../components/SolutionSection"
import VisionSection from "../components/VisionSection"
import OperatingModesSection from "../components/OperatingModesSection"
import ContactSection from "../components/ContactSection"
import "../components/layout.css"
import HowSection from "../components/HowSection"
import WhyTriangulaSection from "../components/WhyTriangulaSection"
import SupportedBySection from "../components/SupportedBySection"
import UseCaseSection from "../components/UseCaseSection"
import ProductSection from "../components/ProductSection"
import SecureDocumentationSection from "../components/SecureDocumentation"
import { BreakpointNumber } from "../components/breakpoints"
import MediaQuery from "react-responsive"

const IndexPage = () => (
  <div id="page-container">
    <SEO title="Next Generation
Gunshot Positioning System" description="Triangula detects and positions gunshots from small firearms by using mobile phones as sensors. The system can be deployed in minutes by installing the Triangula Scout app on three or more mobile phones."/>
    <main id={"content-wrap"}>
      <HeadlineSection />
      <SolutionSection />
      <ProductSection />
      <HowSection />
      <VisionSection />
      <OperatingModesSection />
      <WhyTriangulaSection />
      <SupportedBySection />
        <SecureDocumentationSection />
        <UseCaseSection />
      <ContactSection />
    </main>
    {/* <Footer /> */}
  </div>
)

export default IndexPage
