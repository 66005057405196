import React from "react"
import { backgroundBlue, grey30, primaryBlue } from "./palette"
import styled from "styled-components"
import Padding from "./Padding"
import { Breakpoint } from "./breakpoints"
import LocationIcon from "../images/icons/dark_location.png"
import TimeIcon from "../images/icons/dark_time.png"
import WeaponTypeIcon from "../images/icons/dark_category.png"
import DirectionIcon from "../images/icons/dark_direction.png"

const StyledSection = styled.section`
  background: ${grey30};
  position: relative;
  padding-bottom: 3rem;
  color: white;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`

const Description = styled.p`
  color: ${primaryBlue};
  font-size: 1.4rem;
  text-align: center;
  @media only screen and (min-width: ${Breakpoint.small}) {
    margin-left: 5rem;
    margin-right: 5rem;
  }
`

const Headline = styled.h1`
  margin-bottom: 1rem;
  color: ${primaryBlue};
  font-size: 3rem;
  font-family: raleway;
  font-weight: bolder;
`


const Location = () => (
  <Feature>
    <StyledIcon src={LocationIcon} />
    <div>Location</div>
  </Feature>
)

const Time = () => (
  <Feature>
    <StyledIcon src={TimeIcon} />
    <div>Time</div>
  </Feature>
)

const WeaponType = () => (
  <Feature>
    <StyledIcon src={WeaponTypeIcon} />
    <div>Weapon type</div>
  </Feature>
)

const Direction = () => (
  <Feature>
    <StyledIcon src={DirectionIcon} />
    <div>Gunshot direction</div>
  </Feature>
)

const FeatureList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  
  div {
    color: white;
  }
  @media only screen and (min-width: ${Breakpoint.small}) {
    margin-top: 1rem;
  }
`

const StyledIcon = styled.img`
  height: 22px;
`

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  div {
    font-size: 1.25rem;
    @media only screen and (max-width: ${Breakpoint.small}) {
      font-size: 1rem;
    }
    margin-left: 0.5rem;
    font-family: Open Sans;
    font-weight: bold;
    text-align: center;
    color: ${primaryBlue};
    opacity: 0.8;
  }
`

const Features = () => {
  return (
    <FeatureList>
      <Location />
      <WeaponType />
      <Time />
      <Direction />
    </FeatureList>
  )
}

const SolutionSection = () => {
  return (
    <StyledSection id={"solution"}>
      <Padding>
        <Column>
          <Headline>Solution</Headline>
          <Row>
            <Description>
              Triangula detects and positions gunshots from small firearms by
              using mobile phones as sensors. The system can be deployed in
              minutes by installing the Triangula Scout app on three or more
              mobile phones.
            </Description>
          </Row>
          <Features />
        </Column>
      </Padding>
    </StyledSection>
  )
}

export default SolutionSection
