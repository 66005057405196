import React from "react"
import { grey30, primaryBlue } from "./palette"
import styled from "styled-components"
import Padding from "./Padding"
import ScoutImageSrc from "../images/product_scout.png"
import CloudImageSrc from "../images/product_cloud.png"
import CommanderImageSrc from "../images/product_commander.png"
import ScoutLogo from "../images/scout_logo.png"
import CloudLogo from "../images/cloud_logo.png"
import CommanderLogo from "../images/commander_logo.png"
import MediaQuery from "react-responsive"
import { Breakpoint, BreakpointNumber } from "./breakpoints"

const StyledSection = styled.section`
  position: relative;
  padding-bottom: 3rem;
  color: white;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Description = styled.p`
  color: ${primaryBlue};
  font-size: 1.4rem;
  flex-basis: 25rem;
  flex-grow: 1;
  @media only screen and (min-width: ${Breakpoint.small}) {
  margin-left: 2rem;
  }
`

const Headline = styled.h1`
  margin-bottom: 1rem;
  color: ${primaryBlue};
  font-family: raleway;
  font-weight: bolder;
  font-size: 3rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    font-size: 2rem;
    margin-bottom: 0rem;
  }
`

const Product = styled.div`
  display: flex;
  margin-top: 5rem;
  align-items: center;
  position: relative;
  @media only screen and (max-width: ${Breakpoint.small}) {
    flex-wrap: wrap;
    text-align: center;
    margin-top: 2rem;
  }
  p {
    font-size: 1.125rem;
    @media only screen and (max-width: ${Breakpoint.small}) {
      font-size: 1rem;
    }
  }
`

const ProductImage = styled.div`
  flex-basis: 25rem;
  flex-grow: 1;
  @media only screen and (min-width: ${Breakpoint.small}) {
    margin-right: 5rem;
  }
`

const ImageElem = styled.img`
  width: 100%;
  margin: 1rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    width: 80%;
  }
`

const LogoImg = styled.img`
height: 6rem;
@media only screen and (max-width: ${Breakpoint.small}) {
height: 5rem;
}
`

const Logo = ({ src }) => <LogoImg src={src} />

const ScoutProduct = () => (
  <Product>
    <MediaQuery minDeviceWidth={BreakpointNumber.small}>
      <ProductImage>
        <ImageElem src={ScoutImageSrc} />
      </ProductImage>
    </MediaQuery>

    <Description>
      <Logo src={ScoutLogo} />
      <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
        <ProductImage>
          <ImageElem src={ScoutImageSrc} />
        </ProductImage>
      </MediaQuery>
      <p>Recognizes the audio signature of a gunshot</p>
      <p>Requires three or more mobile phones and/or Apple Watches for positioning</p>
      <p>Running on iOS and Android</p>
      <p>Background processing</p>
      <p>Optimized for battery life</p>
    </Description>
  </Product>
)

const CloudProduct = () => (
  <Product>
    <Description>
      <Logo src={CloudLogo} />

      <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
        <ProductImage>
          <ImageElem src={CloudImageSrc} />
        </ProductImage>
      </MediaQuery>
      <p>Offers instant setup and worldwide coverage</p>
      <p>Developed for various public cloud solutions</p>
      <p>Deploy private cloud for maximum security</p>
    </Description>
    <MediaQuery minDeviceWidth={BreakpointNumber.small}>
      <ProductImage>
        <ImageElem src={CloudImageSrc} />
      </ProductImage>
    </MediaQuery>
  </Product>
)

const CommanderProduct = () => (
  <Product>
    <MediaQuery minDeviceWidth={BreakpointNumber.small}>
      <ProductImage>
        <ImageElem src={CommanderImageSrc} />
      </ProductImage>
    </MediaQuery>
    <Description>
      <Logo src={CommanderLogo} />
      <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
        <ProductImage>
          <ImageElem src={CommanderImageSrc} />
        </ProductImage>
      </MediaQuery>
      <p>Web based operating system on PC/Mac and tablets</p>
      <p>
      Gunshot alerts and planning tool for deployment and
        relocation of sensors
      </p>
      <p>
        Possible to integrate in Operating rooms and Command & Control systems
      </p>
    </Description>
  </Product>
)

const ProductSection = () => {
  return (
    <StyledSection id={"product"}>
      <Padding>
        <Column>
          <ScoutProduct />
          <CloudProduct />
          <CommanderProduct />
        </Column>
      </Padding>
    </StyledSection>
  )
}

export default ProductSection
