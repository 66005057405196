/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"

import styled from "styled-components"
import { Breakpoint } from "./breakpoints"

const DivPadding = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.45rem 1.45rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

interface Props {
  children: JSX.Element
}

const Padding = ({ children }: Props) => {
  return <DivPadding>{children}</DivPadding>
}

export default Padding
