import React, { Suspense } from "react"
import { Breakpoint } from "./breakpoints";
const FlareComponent = React.lazy(() => import('flare-react'));


class GunshotAnimation extends React.Component<any,any> {
    // FlareComponent: any;
    constructor(props) {
        super(props);
        this.state = {loaded: false}
    }
    componentDidMount() {
        this.setState({loaded: true})
    }
    render() {
        if (!this.state.loaded) {
return <div/>
        }
return (

         <Suspense fallback={<div/>}>
            <FlareComponent width={this.props.width} height={this.props.height} animationName="Primary" file={"map_animation.flr"} isPaused={this.props.pause}/>
        </Suspense>)   
    }
  }
const GunshotAnismation = ({height, width}: {height: number, width: number}) => {


    
}

export default GunshotAnimation;