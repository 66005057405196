import React from "react"
import { primaryBlue } from "./palette"
import styled from "styled-components"
import Padding from "./Padding"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledSection = styled.section`
  background: white;
  color: ${primaryBlue};
  position: relative;
  padding-bottom: 3rem;
  color: ${primaryBlue};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Item = styled.p`
  color: ${primaryBlue};
  margin: 0.1rem;
`

const Items = styled.div`
  margin-right: 2rem;
margin-top: 4rem;
`

export const Title = styled.h2`
  color: ${primaryBlue};
  margin-bottom: 0rem;
  font-family: raleway;
  font-weight: bold;
  margin-top: 0;
`

const Anchor = styled.a`
  color: ${primaryBlue};
  margin: 0.1rem;
`

enum ImageType {
  logo = "logo",
}

const Image = ({
  image,
}: {
  image: ImageType
  top?: string
  right?: string
  left?: string
}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "triangula_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img style={{ width: "100%" }} fluid={data[image].childImageSharp.fluid} />
  )
}

const ImgWrapper = styled.div`
  width: 8rem;
`

const SmallItem = styled(Item)`
  max-width: 12rem;
`

const ContactSection = () => {
  return (
    <StyledSection id={"contact"}>
      <Padding>
        <Row>
          <Items>
            <ImgWrapper>
              <Image image={ImageType.logo} />
            </ImgWrapper>
            <Item>
              <SmallItem>
                Triangula is a Norwegian software company founded in 2017
              </SmallItem>
            </Item>
          </Items>
          <Items>
            <Title>Contact us</Title>
            <Item>
              <Anchor href="tel:+4799090930">+47 990 90 930</Anchor>
            </Item>
            <Item>
              <Anchor href="mailto:contact@triangula.no">
                contact@triangula.no
              </Anchor>
            </Item>
            <Item>Dronning Eufemias gate 16</Item>
            <Item>0191 Oslo, NORWAY</Item>
          </Items>
        </Row>
      </Padding>
      <div style={{ height: "10rem" }}></div>
    </StyledSection>
  )
}

export default ContactSection
