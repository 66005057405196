import React from "react"
import { orange, primaryBlue } from "./palette"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import Padding from "./Padding"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Breakpoint } from "./breakpoints"

const StyledSection = styled.section`
  background: white;
  position: relative;
  padding-bottom: 3rem;
  color: ${primaryBlue};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Subsection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${Breakpoint.small}) {
    flex-wrap: wrap;
  }
  margin-bottom: 1em;
  width: 100%;
`
const SubSubSection = styled.div`
  flex-grow: 1;
  max-width: 500px;
  min-width: 200px;
  padding: 1rem;
  @media only screen and (min-width: ${Breakpoint.small}) {
    margin-left: 2rem;
  }
`

const ColorH2 = styled.h2`
  color: ${primaryBlue};
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    text-align: center;
  }
`
enum ImageType {
  fixed = "fixed",
  roaming = "roaming",
  deployed = "deployed",
}

const ImgWrapper = styled.div`
  width: 60px;
`
const Image = ({ image }: { image: ImageType }) => {
  const data = useStaticQuery(graphql`
    query {
      fixed: file(relativePath: { eq: "fixed_sensors.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roaming: file(relativePath: { eq: "roaming_sensor.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deployed: file(relativePath: { eq: "deployed_sensors.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Img fluid={data[image].childImageSharp.fluid} />
}

const FixedSensor = () => {
  return (
    <ImgWrapper>
      <Image image={ImageType.fixed} />
    </ImgWrapper>
  )
}

const RoamingSensor = () => {
  return (
    <ImgWrapper>
      <Image image={ImageType.roaming} />
    </ImgWrapper>
  )
}

const DeployedSensor = () => {
  return (
    <ImgWrapper>
      <Image image={ImageType.deployed} />
    </ImgWrapper>
  )
}

const Description = styled.p`
  opacity: 0.8;
  font-size: 1.125rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    text-align: center;
  }
`

const EndQuote = styled.h2`
  margin-top: 0;
  color: ${orange};
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  max-width: 30rem;
`

const BoldText = styled.p`
  font-weight: bold;
  font-family: Open Sans;
  color: ${primaryBlue};
  opacity: 0.7;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  @media only screen and (max-width: ${Breakpoint.small}) {
    text-align: center;
  }
`

const OperatingModesSection = () => {
  return (
    <StyledSection id={"operating-modes"}>
      <Padding>
        <Column>
          <SectionTitle title={"Operating modes"} />
          <Subsection>
            <FixedSensor />
            <SubSubSection>
              <ColorH2>Fixed sensors</ColorH2>
              <Description>
                Fixed installations for permanent surveillance of high-risk
                objects or critical areas. The sensors are mounted in discrete
                housings and connected to external power.
              </Description>
              <BoldText>Robust coverage</BoldText>
            </SubSubSection>
          </Subsection>
          <Subsection>
            <DeployedSensor />
            <SubSubSection>
              <ColorH2>Deployed sensors</ColorH2>
              <Description>
                Temporary surveillance under an operation. The sensors are
                deployed in the environment running on own battery power.
              </Description>
              <BoldText>Quick setup</BoldText>
            </SubSubSection>
          </Subsection>
          <Subsection>
            <RoamingSensor />
            <SubSubSection>
              <ColorH2>Roaming sensors</ColorH2>
              <Description>
                Mobile surveillance under an operation or as an expansion of a
                fixed installation. Roaming sensors on personnel provides
                continuous gunshot positioning around a team.
              </Description>
              <BoldText>Flexible solution</BoldText>
            </SubSubSection>
          </Subsection>
          <Subsection>
            <EndQuote>
              Select, change or mix operating modes according to needs
            </EndQuote>
          </Subsection>
        </Column>
      </Padding>
    </StyledSection>
  )
}

export default OperatingModesSection
