import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Padding from "./Padding"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { orange } from "./palette"
import GunshotAnimation from "./GunshotAnimation"
import { Breakpoint, BreakpointNumber } from "./breakpoints"
import { useMediaQuery } from 'react-responsive'

const StyledSection = styled.section`
  background: ${orange};
  position: relative;
  padding-bottom: 3rem;
  color: white;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Description = styled.p`
  font-size: 1.25rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  @media only screen and (max-width: ${Breakpoint.medium}) {
    margin-right: 0rem;
    margin-bottom: 0.7rem;
    line-height: 1.25;
    text-align: center;
  }
`

const Headline = styled.h1`
  margin-bottom: 3rem;
  color: white;
  font-family: raleway;
  font-size: 4rem;
  font-weight: bolder;
  @media only screen and (max-width: ${Breakpoint.medium}) {
    font-size: 3rem;
    text-align: center;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${Breakpoint.medium}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const Steps = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  @media only screen and (min-width: ${Breakpoint.medium}) {
    min-height: 25rem;
    margin-right: 2rem;
  }
  @media only screen and (max-width: ${Breakpoint.medium}) {
    margin-top: 1rem;
    align-items: center;
  }
`

function isInViewport(element) {
  if (
    element !== null &&
    element &&
    element.current &&
    element.current !== null
  ) {
    const rect = element.current.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  } else {
    return false
  }
}

const HowSection = () => {
  const [scoutDetects, setScoutDetects] = useState(false)
  const scoutDetectsRef = useRef(null)
  const checkViewPort = useCallback(event => {
    const scoutDetectsInView = isInViewport(scoutDetectsRef)
    if (scoutDetectsInView !== scoutDetects) {
      setScoutDetects(scoutDetectsInView)
    }
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", checkViewPort, true)
    return () => window.removeEventListener("scroll", checkViewPort, true)
  }, [scoutDetects])
  
  const bigScreen = useMediaQuery({ query: '(min-width: 1000px)' })
  const mScreen = useMediaQuery({ query: '(min-width: 800px)' })
  const msScreen = useMediaQuery({ query: '(min-width: 600px)' })
  const sScreen = useMediaQuery({ query: '(min-width: 400px)' })
  const xsScreen = useMediaQuery({ query: '(min-width: 300px)' })

  const width =
    bigScreen
      ? 600
      : mScreen
      ? 400
      : msScreen ? 500
      : sScreen ? 300 : xsScreen ? 250 : 200
  const height = width * 0.7


  return (
    <StyledSection
      id={"how-does-it-work"}
    >
      <Padding>
        <Column>
          <Headline ref={scoutDetectsRef}>How does it work?</Headline>
          <Row>
            <Steps>
              <Description
                className={scoutDetects ? "firstAnimation" : "noOpacity"}
              >
                A gunshot is fired
              </Description>

              <Description
                className={scoutDetects ? "secondAnimation" : "noOpacity"}
              >
                The acoustic fingerprint is detected on sensors
              </Description>
              <Description
                className={scoutDetects ? "thirdAnimation" : "noOpacity"}
              >
                The detection is forwarded to backend
              </Description>
              <Description
                className={scoutDetects ? "fourthAnimation" : "noOpacity"}
              >
                Location and gunshot information is distributed
              </Description>
            </Steps>
                  <GunshotAnimation width={width} height={height} pause={!scoutDetects}/>
          </Row>
        </Column>
      </Padding>
    </StyledSection>
  )
}

export default HowSection
