import React from "react"
import { backgroundBlue, darkBlue, orange, primaryBlue } from "./palette"
import styled from "styled-components"
import Padding from "./Padding"
import { WhiteHeadline } from "./SectionTitle"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Breakpoint, BreakpointNumber } from "./breakpoints"
import MediaQuery from "react-responsive"
import SmartCityImg from "../images/smart_city.jpg"

const StyledSection = styled.section`
  background: ${darkBlue};
  position: relative;
  padding-bottom: 3rem;
  padding-top: 5rem;
  color: white;
`

const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const ImageWrapped = styled.div`
  @media only screen and (max-width: ${Breakpoint.small}) {
    /* display: none; */
    opacity: 0.8;
  }
`
enum ImageType {
  defence = "defence",
  smartcity = "smartcity",
  university = "university",
  event = "event",
  personalsecurity = "personalsecurity",
  bubles = "background_dots",
}

const Image = ({
  image,
  top,
  right,
  left,
}: {
  image: ImageType
  top?: string
  right?: string
  left?: string
}) => {
  const data = useStaticQuery(graphql`
    query {
      defence: file(relativePath: { eq: "defence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      smartcity: file(relativePath: { eq: "smart_city.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      university: file(relativePath: { eq: "university.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      event: file(relativePath: { eq: "event.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personalsecurity: file(relativePath: { eq: "personal_security.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background_dots: file(relativePath: { eq: "background_dots.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (top) {
    return (
      <Img
        style={{
          position: "absolute",
          width: "300px",
          right: right,
          left: left,
          top: top,
          objectFit: "contain",
          zIndex: 0,
        }}
        fluid={data["background_dots"].childImageSharp.fluid}
      />
    )
  } else {
    return (
      <Img
        style={{ width: "100%" }}
        fluid={data[image].childImageSharp.fluid}
      />
    )
  }
}

const Items = styled.div``

const Item = styled.div`
  margin-bottom: 10rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    margin-bottom: 7rem;
  }
`

const SubTitle = styled.h2`
  color: white;
  margin: 0;
  font-size: 4rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
  text-align: center;
    font-size: 2rem;
  }
`

const SmallSubTitle = styled(SubTitle)`
  @media only screen and (max-width: ${Breakpoint.small}) {
  }
`

const BigItem = styled(Item)`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    font-size: 1.25rem;
    opacity: 0.7;
    @media only screen and (min-width: ${Breakpoint.small}) {
      margin-right: 2rem;
    }
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    font-family: Raleway;
  }
`

const SpreadRow = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
`

const SmallItem = styled(Item)`
  display: flex;
  align-items: center;
`

const SmallItemReversed = styled(SmallItem)`
  justify-content: flex-end;
  @media only screen and (max-width: ${Breakpoint.small}) {
    justify-content: center;
  }
`

const SmallItemText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media only screen and (min-width: ${Breakpoint.small}) {
    text-align: left;
    align-items: flex-start;
    margin-left: 3rem;
    margin-right: 2rem;
  }
  p {
    font-size: 1.25rem;
    opacity: 0.7;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
`

const SmallItemTextReversed = styled(SmallItemText)`
  @media only screen and (min-width: ${Breakpoint.small}) {
    align-items: flex-end;
  }
`

const SmallImgContainer = styled.div`
  flex-basis: 31rem;
`

const Centered = styled.p`
  text-align: center;
  margin-bottom: 5rem;
`

const ImageSpacing = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`

const DirectImg = styled.img`
  width: 100%;
  max-width: 700px;
`


const SmallDesc = styled.p`
@media only screen and (max-width: ${Breakpoint.small}) {
  line-height: 1;
  padding-bottom: 1rem;
}
`

const UseCaseSection = () => {
  return (
    <StyledSection id={"use-cases"}>
      <ImageWrapped>
        <Image image={ImageType.bubles} top={"50rem"} right={"2rem"} />
      </ImageWrapped>
      <ImageWrapped>
        <Image image={ImageType.bubles} top={"150rem"} left={"2rem"} />
      </ImageWrapped>
      <Padding>
        <Items>
          <BigItem>
            <SubTitle>Defence</SubTitle>
            <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
              <ImageSpacing>
                <Image image={ImageType.defence} />
              </ImageSpacing>
            </MediaQuery>
            <SpreadRow>
              <Centered>Military objects</Centered>
              <Centered>Base defense</Centered>
              <Centered>Force protection</Centered>
              <Centered>Border control</Centered>
            </SpreadRow>
            <MediaQuery minDeviceWidth={BreakpointNumber.small}>
              <Image image={ImageType.defence} />
            </MediaQuery>
          </BigItem>
          <SmallItem>
            <MediaQuery minDeviceWidth={BreakpointNumber.small}>
              <SmallImgContainer>
                <DirectImg src={SmartCityImg} />
              </SmallImgContainer>
            </MediaQuery>
            <SmallItemText>
              <SmallSubTitle>Smart city</SmallSubTitle>
              <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
                <ImageSpacing>
                  <DirectImg src={SmartCityImg} />
                </ImageSpacing>
              </MediaQuery>
              <SmallDesc>Security of city centers and critical infrastructure</SmallDesc>
              <SmallDesc>Share gunshot warnings across agencies</SmallDesc>
              <SmallDesc>Assist and activate correct CCTV viewport</SmallDesc>
            </SmallItemText>
          </SmallItem>

          <BigItem>
            <SubTitle>Site security</SubTitle>
            <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
              <ImageSpacing>
                <Image image={ImageType.university} />
              </ImageSpacing>
            </MediaQuery>
            <SpreadRow>
              <Centered>Government and municipal buildings</Centered>
              <Centered>Campus safety</Centered>
              <Centered>Power and industrial plants</Centered>
            </SpreadRow>
            <MediaQuery minDeviceWidth={BreakpointNumber.small}>
              <Image image={ImageType.university} />
            </MediaQuery>
          </BigItem>
          <SmallItemReversed>
            <SmallItemTextReversed>
              <SubTitle>Events</SubTitle>
              <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
                <ImageSpacing>
                  <Image image={ImageType.event} />
                </ImageSpacing>
              </MediaQuery>
              <p>Official state visits</p>
              <p>Parades and celebrations</p>
              <p>Sporting events</p>
              <p>Outdoor concerts</p>
              <p>Demonstrations</p>
            </SmallItemTextReversed>
            <MediaQuery minDeviceWidth={BreakpointNumber.small}>
              <SmallImgContainer>
                <Image image={ImageType.event} />
              </SmallImgContainer>
            </MediaQuery>
          </SmallItemReversed>
          <BigItem>
            <SubTitle>Personal protection</SubTitle>
            <MediaQuery maxDeviceWidth={BreakpointNumber.small}>
              <ImageSpacing>
                <Image image={ImageType.personalsecurity} />
              </ImageSpacing>
            </MediaQuery>
            <SpreadRow>
              <p>Government</p>
              <p>Corporate</p>
              <p>Private</p>
            </SpreadRow>
            <MediaQuery minDeviceWidth={BreakpointNumber.small}>
              <Image image={ImageType.personalsecurity} />
            </MediaQuery>
          </BigItem>
        </Items>
      </Padding>
    </StyledSection>
  )
}

export default UseCaseSection
