import React from "react"
import Padding from "./Padding"
import styled from "styled-components"
import { orange, primaryBlue } from "./palette"
import { Breakpoint, BreakpointNumber } from "./breakpoints"
import MediaQuery from "react-responsive"
import Image from "../images/secure_documentation.jpg"
import LocationIcon from "../images/location.png"
import TimeIcon from "../images/time.png"
import WeaponTypeIcon from "../images/weapon_type.png"
import DirectionIcon from "../images/direction.png"

const StyledImg = styled.img`
  width: 100%;
  opacity: 0.8;
  max-width: 960px;
  position: absolute;
  z-index: 0;
  bottom: 5rem;
`

const SectionImage = ({ top }: { top: string }) => (
  <StyledImg style={{ top: top }} src={Image} />
)

const StyledSection = styled.section`
  background: ${orange};
  position: relative;
  padding-bottom: 5rem;
  color: ${primaryBlue};
  overflow: hidden;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 2rem;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: ${Breakpoint.small}) {
    margin-left: 0rem;
  }
`

const ImageAndTextContainer = styled.div`
  position: relative;
`

const Headline = styled.h1`
  margin-bottom: 3rem;
  color: white;
  font-size: 4rem;
  font-family: raleway;
  line-height: 1.2;
  font-weight: bolder;
  letter-spacing: 0.1rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    font-size: 2rem;
  }
`

const SubHeadline = styled.h2`
  font-weight: bold;
  color: white;
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    font-size: 1.2rem;
  }
`

const SubHeaders = styled.div`
  margin-top: 10rem;
  margin-bottom: 20rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
`

const EndText = styled.p`
  
  font-size: 1.75rem;
  max-width: 35rem;
  text-align: center;
  color: white;
  z-index: 2;
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${Breakpoint.small}) {
    justify-content: flex-start;
  }
`

const FeatureList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  
  div {
    color: white;
  }
  @media only screen and (min-width: ${Breakpoint.small}) {
    margin-top: 1rem;
  }
`

const StyledIcon = styled.img`
  height: 30px;
`

const Feature = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  div {
    font-size: 1.25rem;
    margin-top: 0.5rem;
    font-family: raleway;
    text-align: center;
  }
`

const Location = () => (
  <Feature>
    <StyledIcon src={LocationIcon} />
    <div>Location</div>
  </Feature>
)

const Time = () => (
  <Feature>
    <StyledIcon src={TimeIcon} />
    <div>Time</div>
  </Feature>
)

const WeaponType = () => (
  <Feature>
    <StyledIcon src={WeaponTypeIcon} />
    <div>Weapon type</div>
  </Feature>
)

const Direction = () => (
  <Feature>
    <StyledIcon src={DirectionIcon} />
    <div>Gunshot direction</div>
  </Feature>
)

const Features = () => {
  return (
    <FeatureList>
      <Location />
      <WeaponType />
      <Time />
      <Direction />
    </FeatureList>
  )
}

const SecureDocumentationSection = () => {
  return (
    <StyledSection id={"secure-documentation"}>
      <Padding>
        <ImageAndTextContainer>
          <MediaQuery minDeviceWidth={BreakpointNumber.medium}>
            <SectionImage top="11.5rem" />
          </MediaQuery>
          <MediaQuery maxDeviceWidth={BreakpointNumber.medium}>
            <SectionImage top="7.7rem" />
          </MediaQuery>
          <Column>
            <Headline>
              Provides secure
              <br />
              and indisputable
              <br />
              documentation
            </Headline>
            <MediaQuery maxDeviceWidth={BreakpointNumber.medium}>
              <div style={{ height: "10rem", width: "100px" }}></div>
            </MediaQuery>
            <MediaQuery minDeviceWidth={BreakpointNumber.medium}>
              <SubHeaders>
              </SubHeaders>
            </MediaQuery>
          </Column>
        </ImageAndTextContainer>
        <Features />
        <Centered>
          <EndText>
            The information is electronically signed and encrypted to provide
            evidence
          </EndText>
        </Centered>
      </Padding>
    </StyledSection>
  )
}

export default SecureDocumentationSection
