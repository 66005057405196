import React from "react"
import styled from "styled-components"
import { primaryBlue, green, brightOrange } from "./palette"
import Padding from "./Padding"
import { Link } from "gatsby"
import { SecondaryButton } from "./Buttons"
import { Breakpoint } from "./breakpoints"
import VideoBackground from "./VideoBackground"
import  Logo  from "./logo/Logo"


const StyledSection = styled.section`
  background: ${primaryBlue};
  position: relative;
  padding-bottom: 5rem;
  color: white;
  overflow: hidden;
`

const SubDesc = styled.p`
  margin-bottom: 0rem;
  margin-top: 5rem;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
`

const Bullet = styled.p`
  
  font-size: 1.4rem;
  font-weight: bold;
  font-family: raleway;
  text-align: center;
  letter-spacing: 0.05rem;
  color: ${brightOrange};
  margin-left: 1rem;
  margin-right: 1rem;
`

const DescriptionStyled = styled.div`
  line-height: 1.5rem;
  z-index: 2;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`
const Description = () => (
  <DescriptionStyled>
    <SubDesc>
      Triangula is an AI software that detects and positions gunshots
    </SubDesc>
    <Row>
      <Bullet>SITUATION AWARENESS</Bullet>
      <Bullet>FAST RESPONSE</Bullet>
      <Bullet>SECURE DOCUMENTATION</Bullet>
    </Row>
  </DescriptionStyled>
)

const LearnMoreButton = () => (
  <SecondaryButton href={"#contact"}>Get a demo</SecondaryButton>
)

const Headline = styled.h1`
  font-family: raleway;
  margin-bottom: 3rem;
  font-weight: bold;
  text-align: center;
  font-size: 3.125rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    font-size: 2rem;
  }
  letter-spacing: 0.1rem;
  color: white;
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
  position: relative;
`

const HeaderRow = styled.div`
  margin: 2rem 2rem 0 2rem;
  @media only screen and (min-width: ${Breakpoint.small}) {
    margin: 2rem 5rem 0 5rem;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  position: relative;
`

const Title = () => (
  <Headline>
    Next Generation
    <br />
    Gunshot Positioning System
  </Headline>
)

const Content = styled.div`
  position: "absolute";
  z-index: 5;
`

const Header = () => (
  <Logo>
    <Link
      to="/"
      style={{
        color: `white`,
        textDecoration: `none`,
      }}
    >
      Triangula
    </Link>
  </Logo>
)

const HeadlineSection = () => {
  return (
    <StyledSection>
      <VideoBackground />
      <Content>
        <HeaderRow>
          <Header />
          <LearnMoreButton />
        </HeaderRow>
        <Padding>
          <Grid>
            <div>
              <Title />
              <Description />
            </div>
          </Grid>
        </Padding>
      </Content>
    </StyledSection>
  )
}

export default HeadlineSection
