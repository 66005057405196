import React from "react"
import styled from "styled-components"
import { Breakpoint } from "./breakpoints"
import { primaryBlue } from "./palette"

const Headline = styled.h1`
  margin-bottom: 3rem;
  color: ${primaryBlue};
  font-size: 3rem;
  font-family: raleway;
  font-weight: bolder;
  @media only screen and (max-width: ${Breakpoint.small}) {
    text-align: center;
  }

`

export const WhiteHeadline = styled.h1`
  margin-bottom: 3rem;
  color: white;
  font-size: 4rem;
  font-family: raleway;
  font-weight: bolder;
  text-align: center;
`

const SectionTitle = ({ title }: { title: string }) => (
  <Headline>{title}</Headline>
)

export default SectionTitle
