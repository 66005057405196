import React from "react"
import styled from "styled-components"
import Video from "../videos/background-video.mp4"

const BackgroundFader = styled.div`
  opacity: 0.7;
  height: 100%;
  width: 100%;
  background: #000f2e;
  z-index: 2;
  position: absolute;
`

const BackgroundContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
`

const VideoCover = styled.video`
  width: 100%;
  object-fit: cover;
`

const VideoBackground = () => {
  return (
    <BackgroundContainer>
      <BackgroundFader />
      <VideoCover
        className="video-player"
        height="100%"
        width="100%"
        loop
        muted
        autoPlay
      >
        <source src={Video} type="video/mp4" />
      </VideoCover>
    </BackgroundContainer>
  )
}

export default VideoBackground
