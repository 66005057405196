import React from "react"
import { backgroundBlue, orange, primaryBlue } from "./palette"
import styled from "styled-components"
import Padding from "./Padding"
import { WhiteHeadline } from "./SectionTitle"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Breakpoint } from "./breakpoints"
import { SmallButton, SelectedButton } from "./Buttons"

const StyledSection = styled.section`
  background: ${backgroundBlue};
  position: relative;
  padding-bottom: 3rem;
  color: white;
`

const BackgroundImg = () => {
  const data = useStaticQuery(graphql`
    query {
      background_dots: file(relativePath: { eq: "background_dots.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img
      style={{
        position: "absolute",
        width: "300px",
        right: "2rem",
        top: "2rem",
        objectFit: "contain",
        zIndex: 0,
      }}
      fluid={data["background_dots"].childImageSharp.fluid}
    />
  )
}

const Items = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  flex-wrap: wrap;
  @media only screen and (max-width: ${Breakpoint.small}) {
  }
`

const SubTitle = styled.h2`
  color: white;
  margin: 0;
  margin-left: 3rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    text-align: center;
    margin-left: 0;
  }
`

const Description = styled.p`
  max-width: 500px;
  margin-left: 3rem;
  font-size: 1.125rem;
  opacity: 0.8;
  @media only screen and (max-width: ${Breakpoint.small}) {
    text-align: center;
    margin-left: 0;
  }
`

const Orange = styled.p`
  color: ${orange};
  margin: 0;
  font-family: Domine;
`

const Number = styled.span`
  font-size: 7.5rem;
`

const NumberAndSuffix = styled(Orange)`
  margin-top: -2rem;
  font-size: 3.125rem;
`

const MetricContainer = styled.div`
  width: 20rem;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${Breakpoint.small}) {
    justify-content: center;
    margin-bottom: 1rem;
  }
`

const UnderlineSpan = styled.span`
  /* border-bottom: 2px ${orange} solid; */
`

const OrangeHr = styled.hr`
  width: 7rem;
  border: 1px ${orange} solid;
  margin: 0;
  padding: 0;
`

const RightAligned = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -1.5rem;
  @media only screen and (max-width: ${Breakpoint.small}) {
    margin-top: -2rem;
  }
`

const Prefix = styled(Orange)`
  font-family: Open sans;
`

const Metric = ({
  prefix,
  number,
  suffix,
}: {
  prefix: string
  number: string
  suffix: string
}) => {
  return (
    <MetricContainer>
      <div>
        <Prefix>{prefix}</Prefix>
        <NumberAndSuffix>
          <Number>{number}</Number> <UnderlineSpan>{suffix}</UnderlineSpan>
        </NumberAndSuffix>
        <RightAligned>
          <OrangeHr />
        </RightAligned>
      </div>
    </MetricContainer>
  )
}

const ImageWrapped = styled.div`
  @media only screen and (max-width: ${Breakpoint.small}) {
    /* display: none; */
    opacity: 0.8;
  }
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`

const UnitButton = ({selected, name, onClick}:{selected: boolean, name: string, onClick: () => void}) => {
  
  return selected ? <SelectedButton onClick={onClick}>{name}</SelectedButton> : <SmallButton onClick={onClick}>{name}</SmallButton>
}

const WhyTriangulaSection = () => {
  const [unit, setUnit] = React.useState<"metric" | "imperial">("metric") 
  return (
    <StyledSection id={"why-triangula"}>
      <ImageWrapped>
        <BackgroundImg />
      </ImageWrapped>
      <Padding>
        <WhiteHeadline>Why Triangula?</WhiteHeadline>
        <Row>
        <UnitButton name="Metric" selected={unit === "metric"} onClick={() => {setUnit("metric")}}/>
        <UnitButton name="Imperial" selected={unit === "imperial"} onClick={() => {setUnit("imperial")}}/>
        </Row>
        <Items>
          <Item>
            {unit === "metric" ? <Metric prefix={"Up to"} number={"2"} suffix={"km"} /> : <Metric prefix={"Up to"} number={"1.2"} suffix={"mi"} />}
            <FlexGrow>
              <SubTitle>Long detection range</SubTitle>
              <Description>
                Audible range of a gunshot depends on weapon type, gunshot
                direction, background noise and topography.
              </Description>
              <Description>
                The detection range for a rifle in open terrain is up to {unit === "metric" ? `2
                kilometers` : `1.2 miles`}.
              </Description>
            </FlexGrow>
          </Item>
          <Item>
          {unit === "metric" ? <Metric prefix={"Better than"} number={"25"} suffix={"m"} /> : <Metric prefix={"Better than"} number={"27"} suffix={"yd"} />}
            <FlexGrow>
              <SubTitle>Accurate positioning</SubTitle>
              <Description>
                Accuracy depends on distance and angle between sensors.
              </Description>
              <Description>
                Triangula typically positions the shooter within { unit === "metric" ? `25 meters at a
                range of 500 meters`: `27 yards at a
                range of 550 yards`}.
              </Description>
            </FlexGrow>
          </Item>
          <Item>
            <Metric prefix={"Less than"} number={"4"} suffix={"sec"} />
            <FlexGrow>
              <SubTitle>Real time response</SubTitle>
              <Description>
                Triangula detects and alerts virtually in real time.
              </Description>
              <Description>A shooter is mapped within 4 seconds.</Description>
            </FlexGrow>
          </Item>
        </Items>
      </Padding>
    </StyledSection>
  )
}

export default WhyTriangulaSection
